// These need to be refactored to look up existing member
export const LOOK_UP_EXISTING_MEMBER = 'LOOK_UP_EXISTING_MEMBER';
export const LOOK_UP_EXISTING_MEMBER_START = 'LOOK_UP_EXISTING_MEMBER_START';
export const LOOK_UP_EXISTING_MEMBER_SUCCESS = 'LOOK_UP_EXISTING_MEMBER_SUCCESS';
export const LOOK_UP_EXISTING_MEMBER_FAIL = 'LOOK_UP_EXISTING_MEMBER_FAIL';

// These need to be built
export const SET_SIGNATURE = 'SET_SIGNATURE';
export const CLEAR_SIGNATURE = 'CLEAR_SIGNATURE';
export const SIGNATURE_INIT = 'SIGNATURE_INIT';
// export const VALIDATE_SIGNATURE = 'VALIDATE_SIGNATURE';

export const ADD_PRIMARY_DETAILS = 'ADD_PRIMARY_DETAILS';
export const IS_PRIMARY_DETAILS_VALID = 'IS_PRIMARY_DETAILS_VALID';
export const RESET_PRIMARY_DETAILS = 'RESET_PRIMARY_DETAILS';
export const PRIMARY_DETAILS_INIT = 'PRIMARY_DETAILS_INIT';
export const UPDATE_PRIMARY_DETAILS_DATA = 'UPDATE_PRIMARY_DETAILS_DATA';

export const ADD_MEMBER_DETAILS = 'ADD_MEMBER_DETAILS';
export const IS_MEMBER_DETAILS_VALID = 'IS_MEMBER_DETAILS_VALID';
export const RESET_MEMBER_DETAILS = 'RESET_MEMBER_DETAILS';
export const MEMBER_DETAILS_INIT = 'MEMBER_DETAILS_INIT';
export const UPDATE_MEMBER_DETAILS_DATA = 'UPDATE_MEMBER_DETAILS_DATA';

export const ADD_PAYMENT_DETAILS = 'ADD_PAYMENT_DETAILS';
export const IS_PAYMENT_DETAILS_VALID = 'IS_PAYMENT_DETAILS_VALID';
export const RESET_PAYMENT_DETAILS = 'RESET_PAYMENT_DETAILS';
export const PAYMENT_DETAILS_INIT = 'PAYMENT_DETAILS_INIT';
export const UPDATE_PAYMENT_DETAILS_DATA = 'UPDATE_PAYMENT_DETAILS_DATA';

export const ADD_MEMBERSHIP_DETAILS = 'ADD_MEMBERSHIP_DETAILS';
export const IS_MEMBERSHIP_DETAILS_VALID = 'IS_MEMBERSHIP_DETAILS_VALID';
export const RESET_MEMBERSHIP_DETAILS = 'RESET_MEMBERSHIP_DETAILS';
export const MEMBERSHIP_DETAILS_INIT = 'MEMBERSHIP_DETAILS_INIT';
export const UPDATE_MEMBERSHIP_DETAILS_DATA = 'UPDATE_MEMBERSHIP_DETAILS_DATA';
export const UPDATE_MEMBERSHIP_FIRST_BILLING_DATE = 'UPDATE_MEMBERSHIP_FIRST_BILLING_DATE';
export const UPDATE_MEMBERSHIP_CONTRACTS = 'UPDATE_MEMBERSHIP_CONTRACTS';

export const ADD_HEALTH_CHECK = 'ADD_HEALTH_CHECK';
export const IS_HEALTH_CHECK_VALID = 'IS_HEALTH_CHECK_VALID';
export const RESET_HEALTH_CHECK = 'RESET_HEALTH_CHECK';
export const HEALTH_CHECK_INIT = 'HEALTH_CHECK_INIT';
export const UPDATE_HEALTH_CHECK_DATA = 'UPDATE_HEALTH_CHECK_DATA';

export const ADD_LEGAL_DETAILS = 'ADD_LEGAL_DETAILS';
export const IS_LEGAL_DETAILS_VALID = 'IS_LEGAL_DETAILS_VALID';
export const RESET_LEGAL_DETAILS = 'RESET_LEGAL_DETAILS';
export const LEGAL_DETAILS_INIT = 'LEGAL_DETAILS_INIT';
export const UPDATE_LEGAL_DETAILS_DATA = 'UPDATE_LEGAL_DETAILS_DATA';

export const PURCHASE_MEMBERSHIP_INIT = 'PURCHASE_MEMBERSHIP_INIT';
export const PURCHASE_MEMBERSHIP = 'PURCHASE_MEMBERSHIP';
export const PURCHASE_MEMBERSHIP_START = 'PURCHASE_MEMBERSHIP_START';
export const PURCHASE_MEMBERSHIP_SUCCESS = 'PURCHASE_MEMBERSHIP_SUCCESS';
export const PURCHASE_MEMBERSHIP_FAIL = 'PURCHASE_MEMBERSHIP_FAIL';

export const FORM_BUILDER_INIT = 'FORM_BUILDER_INIT';

export const SHOW_MEMBER_DETAILS = 'SHOW_MEMBER_DETAILS';
export const SHOW_PAYMENT_DETAILS = 'SHOW_PAYMENT_DETAILS';
export const SHOW_MEMBERSHIP_DETAILS = 'SHOW_MEMBERSHIP_DETAILS';
export const SHOW_HEALTH_CHECK = 'SHOW_HEALTH_CHECK';
export const SHOW_LEGAL_DETAILS = 'SHOW_LEGAL_DETAILS';

// export const FETCH_USER = 'FETCH_USER';

export const AUTH_CHECK_STATE ='AUTH_CHECK_STATE';
export const AUTH_USER = 'AUTH_USER';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_CHECK_TIMEOUT = 'AUTH_CHECK_TIMEOUT';
export const AUTH_REFRESH_TOKEN = 'AUTH_REFRESH_TOKEN';
// export const AUTH_REFRESH_TOKEN_SUCCESS = 'AUTH_REFRESH_TOKEN_SUCCESS';
// export const AUTH_REFRESH_TOKEN_FAIL = 'AUTH_REFRESH_TOKEN_FAIL';
export const AUTH_INITIATE_LOGOUT = 'AUTH_INITIATE_LOGOUT';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';
export const SET_STAFF_MEMBER = 'SET_STAFF_MEMBER';

export const GET_MEMBERSHIP_DETAILS_START = 'GET_MEMBERSHIP_DETAILS_START';
export const GET_MEMBERSHIP_DETAILS = 'GET_MEMBERSHIP_DETAILS';
export const GET_MEMBERSHIP_DETAILS_FAIL = 'GET_MEMBERSHIP_DETAILS_FAIL';
export const GET_CONTRACTS_START = 'GET_CONTRACTS_START';
export const GET_CONTRACTS = 'GET_CONTRACTS';
export const GET_CONTRACTS_SUCCESS = 'GET_CONTRACTS_SUCCESS';
export const GET_CONTRACTS_FAIL = 'GET_CONTRACTS_FAIL';
export const GET_PERSONAL_TRAINERS_SUCCESS = 'GET_PERSONAL_TRAINERS_SUCCESS';
export const GET_MEMBERSHIP_DETAILS_SUCCESS = 'GET_MEMBERSHIP_DETAILS_SUCCESS';
export const GET_TRAINERS = 'GET_TRAINERS';
export const GET_STAFF_MEMBERS_SUCCESS = 'GET_STAFF_MEMBERS_SUCCESS';

// export const GET_BUNKER_TRAINERS_START = 'GET_BUNKER_TRAINERS_START';
// export const GET_BUNKER_TRAINERS_SUCCESS = 'GET_BUNKER_TRAINERS_SUCCESS';
// export const GET_BUNKER_TRAINERS_FAIL = 'GET_BUNKER_TRAINERS_FAIL';

export const CHECK_DUPLICATE_KEY = 'CHECK_DUPLICATE_KEY';
export const CHECK_DUPLICATE_KEY_SUCCESS = 'CHECK_DUPLICATE_KEY_SUCCESS';
export const CHECK_DUPLICATE_KEY_START = 'CHECK_DUPLICATE_KEY_START';
export const CHECK_DUPLICATE_KEY_FAIL = 'CHECK_DUPLICATE_KEY_FAIL';

export const GET_MANUAL_SUBMISSIONS_START = 'GET_MANUAL_SUBMISSIONS_START';
export const GET_MANUAL_SUBMISSIONS_SUCCESS = 'GET_MANUAL_SUBMISSIONS_SUCCESS';
export const GET_MANUAL_SUBMISSIONS_FAIL = 'GET_MANUAL_SUBMISSIONS_FAIL';
export const GET_MANUAL_SUBMISSIONS = 'GET_MANUAL_SUBMISSIONS';

export const COMPLETE_MANUAL_SUBMISSION_START = 'COMPLETE_MANUAL_SUBMISSION_START';
export const COMPLETE_MANUAL_SUBMISSION_SUCCESS = 'COMPLETE_MANUAL_SUBMISSION_SUCCESS';
export const COMPLETE_MANUAL_SUBMISSION_FAIL = 'COMPLETE_MANUAL_SUBMISSION_FAIL';
export const COMPLETE_MANUAL_SUBMISSION = 'COMPLETE_MANUAL_SUBMISSION';

export const GET_ENROLMENTS_START = 'GET_ENROLMENTS_START';
export const GET_ENROLMENTS_SUCCESS = 'GET_ENROLMENTS_SUCCESS';
export const GET_ENROLMENTS_FAIL = 'GET_ENROLMENTS_FAIL';
export const GET_ENROLMENTS = 'GET_ENROLMENTS';

export const SEARCH_ENROLMENTS_START = 'SEARCH_ENROLMENTS_START';
export const SEARCH_ENROLMENTS_SUCCESS = 'SEARCH_ENROLMENTS_SUCCESS';
export const SEARCH_ENROLMENTS_FAIL = 'SEARCH_ENROLMENTS_FAIL';
export const SEARCH_ENROLMENTS = 'SEARCH_ENROLMENTS';

export const GET_UI_GYMS_START = 'GET_UI_GYMS_START';
export const GET_UI_GYMS = 'GET_UI_GYMS';
export const GET_UI_GYMS_SUCCESS = 'GET_UI_GYMS_SUCCESS';
export const GET_UI_GYMS_FAIL = 'GET_UI_GYMS_FAIL';

export const UPDATE_UI_GYM_START = 'UPDATE_UI_GYM_START';
export const UPDATE_UI_GYM = 'UPDATE_UI_GYM';
export const UPDATE_UI_GYM_SUCCESS = 'UPDATE_UI_GYM_SUCCESS';
export const UPDATE_UI_GYM_FAIL = 'UPDATE_UI_GYM_FAIL';

export const GET_UI_STAFF_START = 'GET_UI_STAFF_START';
export const GET_UI_STAFF = 'GET_UI_STAFF';
export const GET_UI_STAFF_SUCCESS = 'GET_UI_STAFF_SUCCESS';
export const GET_UI_STAFF_FAIL = 'GET_UI_STAFF_FAIL';

export const GET_STAFF_START = 'GET_STAFF_START';
export const GET_STAFF = 'GET_STAFF';
export const GET_STAFF_SUCCESS = 'GET_STAFF_SUCCESS';
export const GET_STAFF_FAIL = 'GET_STAFF_FAIL';

export const UPDATE_STAFF_START = 'UPDATE_STAFF_START';
export const UPDATE_STAFF = 'UPDATE_STAFF';
export const UPDATE_STAFF_SUCCESS = 'UPDATE_STAFF_SUCCESS';
export const UPDATE_STAFF_FAIL = 'UPDATE_STAFF_FAIL';

export const CONTRACT_UPLOAD_ENROLEMNT = 'CONTRACT_UPLOAD_ENROLEMNT';
export const CONTRACT_UPLOAD_ENROLEMNT_START = 'CONTRACT_UPLOAD_ENROLEMNT_START';
export const CONTRACT_UPLOAD_ENROLEMNT_SUCCESS = 'CONTRACT_UPLOAD_ENROLEMNT_SUCCESS';
export const CONTRACT_UPLOAD_ENROLEMNT_FAIL = 'CONTRACT_UPLOAD_ENROLEMNT_FAIL';