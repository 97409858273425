import axios from '../../axios-instance';
import { put } from 'redux-saga/effects';

import * as actions from '../actions/index';

export function* getUIGymsSaga(action) {
    yield put(actions.getUIGymsStart())

    try {
        const response = yield axios.get('getAllGyms?UID='+action.UID);
        // console.log('getUIGymsSaga response: '+JSON.stringify(response.data))
        yield put(actions.getUIGymsSuccess(response.data));
    } catch (error) {
        yield put(actions.getUIGymsFail(error));
    }
}

export function* getUIStaffSaga(action) {
    yield put(actions.getUIStaffStart())

    try {
        const response = yield axios.get('getAllUIStaff?UID='+action.UID);
        // console.log('getUIStaffSaga response: '+JSON.stringify(response.data)) 
        yield put(actions.getUIStaffSuccess(response.data))      
    } catch (error) {
        yield put(action.getUIStaffFail())
    }
}

export function* getStaffSaga(action) {
    yield put(actions.getStaffStart())

    try {
        const response = yield axios.get('getAllStaff?UID='+action.UID);
        // console.log('getStaffSaga response: '+JSON.stringify(response.data)) 
        yield put(actions.getStaffSuccess(response.data))      
    } catch (error) {
        yield put(action.getStaffFail())
    }
}

export function* updateUIGymSaga(action) {
    yield put(actions.updateUIGymStart())

    try {
        // console.log('updateUIGym: '+JSON.stringify(action));
        const response = yield axios.post('updateGym?UID='+action.UID, action.updateUIGymData);
        // console.log('updateUIGym response: '+JSON.stringify(response.data));
        // console.log('updateUIGym response')
        yield put(actions.updateUIGymSuccess(response.data))
    } catch (error) {
        yield put(actions.updateUIGymFail(error));
    }
}

export function* updateStaffSaga(action) {
    yield put(actions.updateStaffStart());

    try {
        // console.log('updateStaff: '+JSON.stringify(action));
        const response = yield axios.post('updateStaff?UID='+action.UID, action.updateStaffData);
        yield put(actions.updateStaffSuccess(response.data))
        // console.log('updateStaff response: '+JSON.stringify(response.data));
    } catch (error) {
        yield put(actions.updateStaffFail(error));
    }
}