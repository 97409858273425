import React from 'react';

import classes from './DrawerToggle.css';
import Logo from '../../../Logo/Logo';

const drawerToggle = (props) => (
    <div className={classes.DrawerToggle} onClick={props.clicked}>
        {/* <div></div>
        <div></div>
        <div></div> */}
        <div className={classes.Logo}>
            <Logo />
        </div>
    </div>
);

export default drawerToggle;