import * as actionTypes from './actionTypes';

export const getEnrolmentsStart = () => {
    return {
        type: actionTypes.GET_ENROLMENTS_START
    }
}

export const getEnrolmentsSuccess = (enrolmentsData) => {
    return {
        type: actionTypes.GET_ENROLMENTS_SUCCESS,
        enrolmentsData: enrolmentsData
    }
}

export const getEnrolmentsFail = (error) => {
    return {
        type: actionTypes.GET_MANUAL_SUBMISSIONS_FAIL,
        error: error
    }
}

export const getEnrolments = (UID, location) => {
    return {
        type: actionTypes.GET_ENROLMENTS,
        UID: UID,
        location: location
    }
}

export const searchEnrolmentsStart = () => {
    return {
        type: actionTypes.SEARCH_ENROLMENTS_START
    }
}

export const searchEnrolmentsSuccess = (enrolmentsData) => {
    return {
        type: actionTypes.SEARCH_ENROLMENTS_SUCCESS,
        enrolmentsData: enrolmentsData
    }
}

export const searchEnrolmentsFail = (error) => {
    return {
        type: actionTypes.SEARCH_ENROLMENTS_FAIL,
        error
    }
}

export const searchEnrolments = (UID, searchData) => {
    return {
        type: actionTypes.SEARCH_ENROLMENTS,
        UID: UID,
        searchData: searchData
    }
}
