import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    legalData: null,
    complete: false,
    error: null,
    loading: false,
    isDuplicateKey: false
}

const legalDetailsComplete = (state, action) => {

    const updatedState = {
        legalData: action.legalData
    }

    return updateObject(state, updatedState);
}

const resetLegalDetails = (state) => {
    return updateObject(state, initialState);
}

const isLegalDetailsValid = (state, action) => {

    const updatedState = {
        complete: action.valid
    }

    return updateObject(state, updatedState)
}

const checkDuplicateKeySuccess = (state, action) => {
    const updatedState = {
        isDuplicateKey: action.isDuplicateKey
    }

    return updateObject(state, updatedState);
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_LEGAL_DETAILS:
            return legalDetailsComplete(state, action);
        case actionTypes.IS_LEGAL_DETAILS_VALID:
            return isLegalDetailsValid(state, action);
        case actionTypes.RESET_LEGAL_DETAILS:
            return resetLegalDetails(state);
        case actionTypes.CHECK_DUPLICATE_KEY_START:
            return updateObject(state, { error: null, loading: true });
        case actionTypes.CHECK_DUPLICATE_KEY_FAIL:
            return updateObject(state, { error: action.error, loading: false });
        case actionTypes.CHECK_DUPLICATE_KEY_SUCCESS:
            return checkDuplicateKeySuccess(state, action);
        case actionTypes.LEGAL_DETAILS_INIT:
            return updateObject(state, initialState)
        case actionTypes.UPDATE_LEGAL_DETAILS_DATA:
            return updateObject(state, { legalData: action.legalData })
        default:
            return state;
    }
}

export default reducer;