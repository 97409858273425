import React from 'react';

import classes from './Home.css';
import Aux from '../../hoc/Aux/Aux';
import LogoMain from '../Logo/LogoMain';

const home = (props) => {

    return (
        <Aux>            
            <div className={classes.Home}>
                <LogoMain />
            </div>
        </Aux>
    );
}

export default home;