import axios from '../../axios-instance';
import { put } from 'redux-saga/effects';

import * as actions from '../actions/index';

export function* getEnrolmentsSaga(action) {
    yield put(actions.getEnrolmentsStart());
    try {
        const response = yield axios.get('getEnrolments?UID='+action.UID+'&location='+action.location)
        // console.log("[getEnrolmentsSuccess] "+JSON.stringify(response.data))
        yield put(actions.getEnrolmentsSuccess(response.data))
    } catch (error) {
        yield put(actions.getEnrolmentsFail(error, action.formData));
    }
}

export function* searchEnrolmentsSaga(action) {
    // console.log("[searchEnrolmentsSaga] action: "+JSON.stringify(action.searchData))
    yield put(actions.searchEnrolmentsStart());
    try {
        const response = yield axios.post('searchEnrolments?UID='+action.UID, action.searchData)
        // console.log("[searchEnrolmentsSaga] response "+JSON.stringify(response.data))
        yield put(actions.searchEnrolmentsSuccess(response.data))
    } catch (error) {
        yield put(actions.searchEnrolmentsFail(error, action.formData));
    }
}