import * as actionTypes from './actionTypes';
// import { setStaffMember } from './auth';

export const membershipDetailsComplete = (membershipData, memberContracts, firstBillingDate) => {
    return {
        type: actionTypes.ADD_MEMBERSHIP_DETAILS,
        membershipData: membershipData,
        memberContracts: memberContracts,
        firstBillingDate: firstBillingDate
    }
}

export const isMembershipDetailsValid = (valid) => {
    return {
        type: actionTypes.IS_MEMBERSHIP_DETAILS_VALID,
        valid: valid
    }
}

export const resetMembershipDetails = () => {
    return {
        type: actionTypes.RESET_MEMBERSHIP_DETAILS
    }
}

export const getMembershipDetails = (locationId) => {
    return {
        type: actionTypes.GET_MEMBERSHIP_DETAILS,
        locationId: locationId
    }
}

export const getMembershipDetailsStart = () => {
    return {
        type: actionTypes.GET_MEMBERSHIP_DETAILS_START
    }

}

export const getContractsStart = () => {
    return {
        type: actionTypes.GET_CONTRACTS_START
    }
}

export const getContracts = (locationId) => {
    return {
        type: actionTypes.GET_CONTRACTS,
        locationId: locationId
    }
}

export const getContractsSuccess = (contractsData) => {
    return {
        type: actionTypes.GET_CONTRACTS_SUCCESS,
        contractsData: contractsData
    }
}

export const getContractsFail = (error) => {
    return {
        type: actionTypes.GET_CONTRACTS_FAIL,
        error: error
    }
}

export const getPersonalTrainersSuccess = (personalTrainersData) => {
    return {
        type: actionTypes.GET_PERSONAL_TRAINERS_SUCCESS,
        personalTrainersData: personalTrainersData
    }
}

export const getMembershipDetailsFail = (error) => {
    return {
        type: actionTypes.GET_MEMBERSHIP_DETAILS_FAIL,
        error: error
    }
}

export const getPersonalMembershipDetailsSuccess = () => {
    return {
        type: actionTypes.GET_MEMBERSHIP_DETAILS_SUCCESS
    }
}

export const getStaffMemberSuccess = (staffMemberData) => {
    return {
        type: actionTypes.GET_STAFF_MEMBERS_SUCCESS,
        staffMemberData: staffMemberData
    }
}

export const getTrainers = (locationId) => {
    return {
        type: actionTypes.GET_TRAINERS,
        locationId: locationId
    }
}

export const initialiseMembershipDetails = () => {
    return {
        type: actionTypes.MEMBERSHIP_DETAILS_INIT
    }
}

export const updateMembershipDetailsData = (membershipData) => {
    return {
        type: actionTypes.UPDATE_MEMBERSHIP_DETAILS_DATA,
        membershipData: membershipData
    }
}

export const updateMembershipFirstBillingDate = (firstBillingDate) => {
    return {
        type: actionTypes.UPDATE_MEMBERSHIP_FIRST_BILLING_DATE,
        firstBillingDate: firstBillingDate
    }
}

export const updateMembershipContracts = (membershipContracts) => {
    return {
        type: actionTypes.UPDATE_MEMBERSHIP_CONTRACTS,
        memberContracts: membershipContracts,
    }
}
