import React, { Component } from 'react';

import fpLogo from '../../assets/images/one_playground_logo_black_1.png';
import bunkerLogo from '../../assets/images/one_playground_logo_black_1.png'
// import bunkerLogo from '../../assets/images/TheBunker_Logo.png'
import classes from './LogoMain.css';

class LogoMain extends Component {

    render() {
        const logo = Math.abs(parseInt(localStorage.getItem('locationId'))) === 5 ? bunkerLogo : fpLogo;
        return (
            <div className={classes.LogoMain} style={{ height: this.props.height }}>
                <img src={logo} alt="FP Forms" />
            </div>
        )
    }

}

export default LogoMain;
