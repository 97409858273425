import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
// import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import primaryDetailsReducer from './store/reducers/primaryDetails';
import memberReducer from './store/reducers/memberDetails';
import paymentReducer from './store/reducers/paymentDetails';
import membershipReducer from './store/reducers/membershipDetails'
import healthCheckReducer from './store/reducers/healthCheck';
import legalReducer from './store/reducers/legalDetails';
import authReducer from './store/reducers/auth';
import signatureReducer from './store/reducers/signature';
import formBuilderReducer from './store/reducers/formBuilder';
import manualSubmissionsReducer from './store/reducers/manualSubmissions';
import viewEnrolmentsReducer from './store/reducers/viewEnrolments';
import uiDetailsReducer from './store/reducers/uiDetails';
import { watchAuth,
    watchClient,
    watchMemberships,
    watchForms,
    watchLegal,
    watchManualSubmissions,
    watchViewEnrolments,
    watchUIDetails } from './store/sagas/index';

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;

const rootReducer = combineReducers({
    primaryDetails: primaryDetailsReducer,
    auth: authReducer,
    signature: signatureReducer,
    legalDetails: legalReducer,
    healthCheck: healthCheckReducer,
    memberDetails: memberReducer,
    paymentDetails: paymentReducer,
    membershipDetails: membershipReducer,
    formBuilder: formBuilderReducer,
    manualSubmissions: manualSubmissionsReducer,
    viewEnrolments: viewEnrolmentsReducer,
    uiDetails: uiDetailsReducer
});

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(watchAuth);
sagaMiddleware.run(watchClient);
sagaMiddleware.run(watchMemberships);
sagaMiddleware.run(watchForms);
sagaMiddleware.run(watchLegal);
sagaMiddleware.run(watchManualSubmissions);
sagaMiddleware.run(watchViewEnrolments);
sagaMiddleware.run(watchUIDetails);

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
)

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
