import * as actionTypes from './actionTypes';

export const memberDetailsComplete = (memberData) => {
    return {
        type: actionTypes.ADD_MEMBER_DETAILS,
        memberData: memberData
    }
}

export const isMemberDetailsValid = (valid) => {
    return {
        type: actionTypes.IS_MEMBER_DETAILS_VALID,
        valid: valid
    }
}

export const resetMemberDetails = () => {
    return {
        type: actionTypes.RESET_MEMBER_DETAILS
    }
}

export const initialiseMemberDetails = () => {
    return {
        type: actionTypes.MEMBER_DETAILS_INIT
    }
}

export const updateMemberDetailsData = (memberData) => {
    return {
        type: actionTypes.UPDATE_MEMBER_DETAILS_DATA,
        memberData: memberData
    }
}