import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    enrolmentsData: null,
    error: null,
    loading: false
}

const setEnrolments = (state, action) => {
    // console.log('[setEnrolments] '+JSON.stringify(action.enrolmentsData))

    let enrolmentList = [];
    if (action.enrolmentsData !== null) {
        enrolmentList = action.enrolmentsData.filter(s => (
            parseInt(s.accessKeyNumber) !== 100042264
        ));
    }

    const sorted = enrolmentList.sort(function (a, b) {
        let d_a = new Date(a.createDate.replace(' ', 'T')), d_b = new Date(b.createDate.replace(' ', 'T'));
        return d_b - d_a;
    });

    // console.log('[setEnrolments] '+JSON.stringify(sorted))

    const updatedState = {
        enrolmentsData: sorted,
        loading: false,
        submitted: false
    }

    return updateObject(state, updatedState);
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ENROLMENTS_START:
            return updateObject(state, { error: null, loading: true });
        case actionTypes.GET_ENROLMENTS_SUCCESS:
            return setEnrolments(state, action);
        case actionTypes.GET_ENROLMENTS_FAIL:
            return updateObject(state, { error: action.error, loading: false });
        case actionTypes.SEARCH_ENROLMENTS_START:
            return updateObject(state, { error: null, loading: true });
        case actionTypes.SEARCH_ENROLMENTS_SUCCESS:
            return setEnrolments(state, action);
        case actionTypes.SEARCH_ENROLMENTS_FAIL:
            return updateObject(state, { error: action.error, loading: false });
        default:
            return state;
    }
}


export default reducer;