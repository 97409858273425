import * as actionTypes from './actionTypes';

export const healthCheckComplete = (healthCheckData) => {
    return {
        type: actionTypes.ADD_HEALTH_CHECK,
        healthCheckData: healthCheckData,
    }
}

export const isHealthCheckValid = (valid) => {
    return {
        type: actionTypes.IS_HEALTH_CHECK_VALID,
        valid: valid
    }
}

export const resetHealthCheck = () => {
    return {
        type: actionTypes.RESET_HEALTH_CHECK
    }
}

export const initialiseHealthCheck = () => {
    return {
        type: actionTypes.HEALTH_CHECK_INIT
    }
}

export const updateHealthCheckData = (healthCheckData) => {
    return {
        type: actionTypes.UPDATE_HEALTH_CHECK_DATA,
        healthCheckData: healthCheckData
    }
}