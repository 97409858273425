import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    membershipData: null,
    memberContracts: null,
    firstBillingDate: null,
    complete: false,
    contractsData: null,
    personalTrainersData: null,
    staffMemberData: null,
    error: null,
    loading: false
}

const membershipDetailsComplete = (state, action) => {
    // console.log('membershipDetailsComplete(reducer)'+JSON.stringify(action))
    const updatedState = {
        membershipData: action.membershipData,
        memberContracts: action.memberContracts,
        firstBillingDate: action.firstBillingDate
    }

    return updateObject(state, updatedState);
}

const resetMembershipDetails = (state) => {
    return updateObject(state, initialState);
}

const isMembershipDetailsValid = (state, action) => {

    // console.log('isMembershipDetailsValid(reducer)' + JSON.stringify(action))

    const updatedState = {
        complete: action.valid
    }

    return updateObject(state, updatedState)
}

const setContracts = (state, action) => {
    const updatedState = {
        contractsData: action.contractsData,
        error: null,
        loading: false
    }

    return updateObject(state, updatedState);
}

const setPersonalTrainers = (state, action) => {

    const sorted = action.personalTrainersData.sort(function (a, b) {
        let nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });

    // console.log("[setPersonalTrainers] SORTED "+JSON.stringify(sorted))
    // console.log("[setPersonalTrainers] "+JSON.stringify(action.personalTrainersData))

    const updatedState = {
        personalTrainersData: sorted
    }
    return updateObject(state, updatedState);
}

const setStaffMember = (state, action) => {

    const sorted = action.staffMemberData.sort(function (a, b) {
        let nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });

    // console.log('[membership Reducer] staffList ' + JSON.stringify(sorted))

    const updatedState = {
        staffMemberData: sorted
    }
    return updateObject(state, updatedState);
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_MEMBERSHIP_DETAILS:
            return membershipDetailsComplete(state, action);
        case actionTypes.IS_MEMBERSHIP_DETAILS_VALID:
            return isMembershipDetailsValid(state, action);
        case actionTypes.RESET_MEMBERSHIP_DETAILS:
            return resetMembershipDetails(state);
        case actionTypes.GET_MEMBERSHIP_DETAILS_START:
            return updateObject(state, { error: null, loading: true });
        case actionTypes.GET_CONTRACTS_START:
            return updateObject(state, { error: null, loading: true });
        case actionTypes.GET_CONTRACTS_SUCCESS:
            return setContracts(state, action);
        case actionTypes.GET_CONTRACTS_FAIL:
            return updateObject(state, { error: action.error, loading: false });
        case actionTypes.GET_PERSONAL_TRAINERS_SUCCESS:
            return setPersonalTrainers(state, action);
        case actionTypes.GET_MEMBERSHIP_DETAILS_FAIL:
            return updateObject(state, { error: action.error, loading: false });
        case actionTypes.GET_MEMBERSHIP_DETAILS_SUCCESS:
            return updateObject(state, { error: null, loading: false });
        case actionTypes.GET_STAFF_MEMBERS_SUCCESS:
            return setStaffMember(state, action);
        case actionTypes.MEMBERSHIP_DETAILS_INIT:
            return updateObject(state, initialState);
        case actionTypes.UPDATE_MEMBERSHIP_DETAILS_DATA:
            return updateObject(state, { membershipData: action.membershipData });
        case actionTypes.UPDATE_MEMBERSHIP_FIRST_BILLING_DATE:
            return updateObject(state, { firstBillingDate: action.firstBillingDate })
        case actionTypes.UPDATE_MEMBERSHIP_CONTRACTS:
            return updateObject(state, { memberContracts: action.memberContracts })
        default:
            return state;
    }
}

export default reducer;