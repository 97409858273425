import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    primarySignatureURL: null,
    primaryValid: false,

    paymentAuthSignatureURL: null,
    paymentAuthValid: false,

    under18SignatureURL: null,
    under18Valid: true,

    valid: false
}

const clearSignature = (state, action) => {

    let updatedState = { ...state };

    // console.log("[signature reducer] action " + JSON.stringify(action))

    switch (action.identifier) {
        case 'primary':
            updatedState.primarySignatureURL = null;
            updatedState.primaryValid = false;
            break;
        case 'paymentAuth':
            updatedState.paymentAuthSignatureURL = null;
            updatedState.paymentAuthValid = false;
            break;
        case 'under18Signature':
            updatedState.under18SignatureURL = null;
            updatedState.under18Valid = false;
            break;
        default:
            updatedState = state;
    }

    updatedState.valid = updatedState.primaryValid && updatedState.paymentAuthValid && updatedState.under18Valid; // && allOther.valid

    // console.log("[signature reducer] clearSignatuer " + JSON.stringify(updatedState))

    return updateObject(state, updatedState);
}

const updateSignature = (state, action) => {

    let updatedState = { ...state };

    // console.log("[signature reducer] action " + JSON.stringify(action))

    switch (action.identifier) {
        case 'primary':
            updatedState.primarySignatureURL = action.signatureURL;
            updatedState.primaryValid = action.valid;
            break;
        case 'paymentAuth':
            updatedState.paymentAuthSignatureURL = action.signatureURL;
            updatedState.paymentAuthValid = action.valid;
            break;
        case 'under18Signature':
            updatedState.under18SignatureURL = action.signatureURL;
            updatedState.under18Valid = action.valid;
            break;
        default:
            updatedState = state;
    }

    updatedState.valid = updatedState.primaryValid && updatedState.paymentAuthValid && updatedState.under18Valid; // && allOther.valid

    // console.log("[signature reducer] updatedState " + JSON.stringify(updatedState))
    // console.log("[signature reducer] valid " + updatedState.valid)

    return updateObject(state, updatedState);

}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SIGNATURE:
            return updateSignature(state, action);
        case actionTypes.CLEAR_SIGNATURE:
            return clearSignature(state, action);
        case actionTypes.SIGNATURE_INIT:
            return updateObject(state, initialState);
        default:
            return state
    }
}

export default reducer;