import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    manualSubmissionsData: null,
    error: null,
    loading: false,
    submitted: false,
    isUploadingContract: false
}

const setManualSubmissions = (state, action) => {
    // console.log('[setManualSubmissions] '+JSON.stringify(action.manualSubmissionsData))
    const updatedState = {
        manualSubmissionsData: action.manualSubmissionsData,
        loading: false,
        submitted: false,
        isUploadingContract: false
    }

    return updateObject(state, updatedState);
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_MANUAL_SUBMISSIONS_START:
            return updateObject(state, { error: null, loading: true });
        case actionTypes.GET_MANUAL_SUBMISSIONS_SUCCESS:
            return setManualSubmissions(state, action);
        case actionTypes.GET_MANUAL_SUBMISSIONS_FAIL:
            return updateObject(state, { error: action.error, loading: false });
        case actionTypes.COMPLETE_MANUAL_SUBMISSION_START:
            return updateObject(state, { error: null, loading: true });
        case actionTypes.COMPLETE_MANUAL_SUBMISSION_SUCCESS:
            return updateObject(state, { error: null, loading: false, submitted: true });
        case actionTypes.COMPLETE_MANUAL_SUBMISSION_FAIL:
            return updateObject(state, { error: action.error, loading: false });        
        case actionTypes.CONTRACT_UPLOAD_ENROLEMNT_START:            
            return updateObject(state, { error: null, isUploadingContract: true });
        case actionTypes.CONTRACT_UPLOAD_ENROLEMNT_SUCCESS:            
            return updateObject(state, { error: null, isUploadingContract: false, submitted: true });
        case actionTypes.CONTRACT_UPLOAD_ENROLEMNT_FAIL:            
            return updateObject(state, { error: action.error, isUploadingContract: false });        
        default:
            return state;
    }
}

export default reducer;