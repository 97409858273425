import axios from '../../axios-instance';
import { put } from 'redux-saga/effects';

import * as actions from '../actions/index';

export function* lookUpExistingMemberSaga(action) {
    yield put(actions.lookUpExistingMemberStart())

    try {
        const response = yield axios.post('searchForExistingClient', action.primaryDetailsData)
        if (Object.keys(response.data).length > 0) {
            yield put(actions.lookUpExistingMemberSuccess(response.data, true))
        } else {
            yield put(actions.lookUpExistingMemberSuccess(action.primaryDetailsData, false))
        }
    } catch (error) {
        yield put(actions.lookUpExistingMemberFail(error));
    }
}


