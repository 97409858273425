import React from 'react';

import formsLogo from '../../assets/images/FP-Forms-Icon-Black.png';
import classes from './Logo.css';

const logo = (props) => (
    <div className={classes.Logo} style={{height: props.height}}>
        <img src={formsLogo} alt="FP Forms" />
    </div>
);

export default logo;