import axios from '../../axios-instance';
import { put } from 'redux-saga/effects';

import * as actions from '../actions/index';

export function* checkDuplicateKeySaga(action) {
    yield put(actions.checkDuplicatKeyStart());

    try {
        const response = yield axios.get('searchDuplicateKey/'+action.accessKeyNumber);
        yield put(actions.checkDuplicateKeySuccess(response.data));

    } catch (error) {
        yield put(actions.checkDuplicatKeyFail(error));
    }
}