import * as actionTypes from './actionTypes';

export const primaryDetailsComplete = (primaryDetailsData) => {
    return {
        type: actionTypes.ADD_PRIMARY_DETAILS,
        primaryDetailsData: primaryDetailsData
    }
}


export const isPrimaryDetailsValid = (valid) => {
    return {
        type: actionTypes.IS_PRIMARY_DETAILS_VALID,
        valid: valid
    }
}

export const resetPrimaryDetails = () => {
    return {
        type: actionTypes.RESET_PRIMARY_DETAILS
    }
}

export const lookUpExistingMemberSuccess = (primaryDetailsData, existingClient) => {

    // console.log("lookUpExistingMemberSuccess() existingClient: "+existingClient)

    return {
        type: actionTypes.LOOK_UP_EXISTING_MEMBER_SUCCESS,
        primaryDetailsData: primaryDetailsData,
        existingClient: existingClient
    };
};

export const lookUpExistingMemberFail = (error) => {
    return {
        type: actionTypes.LOOK_UP_EXISTING_MEMBER_FAIL,
        error: error
    };
};

export const lookUpExistingMemberStart = () => {
    // console.log('lookUpExistingMemberStart')
    return {
        type: actionTypes.LOOK_UP_EXISTING_MEMBER_START
    }
};

export const lookUpExistingMember = (primaryDetailsData) => {
    return {
        type: actionTypes.LOOK_UP_EXISTING_MEMBER,
        primaryDetailsData: primaryDetailsData
    }
}

export const initialisePrimaryDetails = () => {
    return {
        type: actionTypes.PRIMARY_DETAILS_INIT
    }
}

export const updatePrimaryDetailsData = (primaryDetailsData) => {
    return {
        type: actionTypes.UPDATE_PRIMARY_DETAILS_DATA,
        primaryDetailsData: primaryDetailsData
    }
}
