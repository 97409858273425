import axios from 'axios';

import { put, call } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import * as Constants from '../../shared/constants';

import * as actions from '../actions/index';

export function* logoutSaga(action) {
    yield call([localStorage, "removeItem"], "token");
    yield call([localStorage, "removeItem"], "expirationDate");
    yield call([localStorage, "removeItem"], "userId");
    yield call([localStorage, "removeItem"], "refreshToken");
    yield call([localStorage, "removeItem"], "refreshCount");
    yield call([localStorage, "removeItem"], "name");
    yield call([localStorage, "removeItem"], "locationId");
    yield call([localStorage, "removeItem"], "permission");
    yield put(actions.logoutSucceed());
}

export function* checkAuthTimeoutSaga(action) {

    const refreshToken = yield localStorage.getItem('refreshToken');
    let refreshCount = yield parseInt(localStorage.getItem('refreshCount'));

    // console.log("[checkAuthTimeoutSaga] refreshCount "+refreshCount)
    // console.log("[checkAuthTimeoutSaga] refreshToken "+refreshToken)

    // refreshCount = parseInt(refreshCount);

    // this is to log out after N hours
    // if (refreshCount > 10) {
        // console.log("[checkAuthTimeoutSaga] LOGOUT")
    //     yield put(actions.logout());
    // } else {
        yield delay(action.experationTime * 1000 - 10000);
        yield put(actions.refreshToken(refreshToken, refreshCount))
    // }

}

export function* authUserSaga(action) {
    yield put(actions.authStart());

    const authData = {
        email: action.email,
        password: action.password,
        returnSecureToken: true
    }

    const url = 'https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPassword?key=AIzaSyCqVxKsEKHEkXyTDlit7ICJCl9BsUKzjA0';

    const BASE_URL = Constants.testInvocation ? Constants.TEST_BASE_URL : Constants.BASE_URL;
    const getStaffMemberUrl = BASE_URL+'getStaffByFirebaseId'
    // const getStaffMemberUrl = BASE_URL+'getStaffMemberByFirebaseId'
    // console.log('[AUTH] BASE_URL '+getStaffMemberUrl)

    // TESTING - Sign up new user
    // const url = 'https://www.googleapis.com/identitytoolkit/v3/relyingparty/signupNewUser?key=AIzaSyCqVxKsEKHEkXyTDlit7ICJCl9BsUKzjA0';

    try {
        const response = yield axios.post(url, authData);

        // console.log("[AUTH] respomse "+ JSON.stringify(response))

        const staffLookUpData = {
            email: response.data.email,
            firebaseId: response.data.localId
        }

        const staffLookUpResponse = yield axios.post(getStaffMemberUrl, staffLookUpData);
        // console.log("[authUserSaga] staffLookUpResponse: " + JSON.stringify(staffLookUpResponse));
        // console.log("[authUserSaga] staffLookUpResponse Data Length: " + Object.keys(staffLookUpResponse.data).length);

        if (Object.keys(staffLookUpResponse.data).length === 0) {
            const error = {
                code: 400,
                message: 'MBO_UNAUTHORISED',
                errors: [
                    {
                        message: 'MBO_UNAUTHORISED',
                        domain: "global",
                        reason: "invalid"
                    }
                ]
            }

            yield put(actions.authFail(error));
        } else {
            const expirationDate = yield new Date(new Date().getTime() + response.data.expiresIn * 1000);
            yield localStorage.setItem('token', response.data.idToken);
            yield localStorage.setItem('expirationDate', expirationDate);
            yield localStorage.setItem('userId', response.data.localId);
            yield localStorage.setItem('refreshToken', response.data.refreshToken);
            yield localStorage.setItem('refreshCount', 1);
            yield localStorage.setItem('name', staffLookUpResponse.data.firstName);
            yield localStorage.setItem('locationId', staffLookUpResponse.data.locationId === null ? 0 : staffLookUpResponse.data.locationId);
            yield localStorage.setItem('permission', staffLookUpResponse.data.permissionLevel);
            // yield put(actions.setStaffMember(staffLookUpResponse.data));
            yield put(actions.authSuccess(response.data.idToken, response.data.localId));
            yield put(actions.checkAuthTimeout(response.data.expiresIn));
        }

    } catch (error) {
        yield put(actions.authFail(error.response.data.error));
    }
}

export function* authCheckStateSaga(action) {
    const token = yield localStorage.getItem('token');
    if (!token) {
        yield put(actions.logout());
    } else {
        const expirationDate = yield new Date(localStorage.getItem('expirationDate'));
        if (expirationDate <= new Date()) {
            yield put(actions.logout());
        } else {
            const userId = yield localStorage.getItem('userId');
            yield put(actions.authSuccess(token, userId));
            yield put(actions.checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000));
        }
    }
}

export function* authRefreshTokenSaga(action) {
    const url = 'https://securetoken.googleapis.com/v1/token?key=AIzaSyCqVxKsEKHEkXyTDlit7ICJCl9BsUKzjA0';

    const cnt = parseInt(action.refreshCount)

    const refreshData = {
        grant_type: "refresh_token",
        refresh_token: action.refreshToken
    }

    try {
        // console.log("[authRefreshTokenSaga] About to refresh token")
        const response = yield axios.post(url, refreshData);
        // console.log("[authRefreshTokenSaga] success: " + JSON.stringify(response))
        const expirationDate = yield new Date(new Date().getTime() + response.data.expires_in * 1000);
        yield localStorage.setItem('token', response.data.id_token);
        yield localStorage.setItem('expirationDate', expirationDate);
        yield localStorage.setItem('userId', response.data.user_id);
        yield localStorage.setItem('refreshToken', response.data.refresh_token)
        yield localStorage.setItem('refreshCount', cnt + 1)
        yield put(actions.authSuccess(response.data.id_token, response.data.user_id));
    } catch (error) {
        yield put(actions.logout());
    }
}
