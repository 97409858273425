import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initalState = {
    token: null,
    userId: null,
    refreshToken: null,
    error: null,
    loading: false,
    authRedirectPath: '/',
    staffName: null,
    staffPermission: null,
    staffMboId: null,
    staffLocationId: null
}

const authSuccess = (state, action) => {
    return updateObject(state, {
        token: action.idToken,
        userId: action.userId,
        refreshToken: action.refreshToken,
        error: null,
        loading: false,
    });
};

const authLogout = (state, action) => {
    return updateObject(state, {
        token: null,
        userId: null
    });
};

const setAuthRedirectPath = (state, action) => {
    return updateObject(state, { authRedirectPath: action.path });
}

const setStaffMember = (state, action) => {

    // console.log("[reducer] setStaffMember"+JSON.stringify(action))

    return updateObject(state, {
        staffName: action.staffMember.firstName,
        staffPermission: action.staffMember.staffPermission,
        staffMboId: action.staffMember.staffMboId,
        staffLocationId: action.staffMember.locationId
    })
}

const reducer = (state = initalState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START:
            return updateObject(state, { error: null, loading: true })
        case actionTypes.AUTH_SUCCESS:
            return authSuccess(state, action);
        case actionTypes.AUTH_FAIL:
            return updateObject(state, { error: action.error, loading: false });
        case actionTypes.AUTH_LOGOUT:
            return authLogout(state, action);
        case actionTypes.SET_AUTH_REDIRECT_PATH:
            return setAuthRedirectPath(state, action);
        case actionTypes.SET_STAFF_MEMBER:
            return setStaffMember(state, action);
        default:
            return state;
    }

}

export default reducer;