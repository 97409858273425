import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    healthCheckData: null,
    complete: false
}

const healthCheckComplete = (state, action) => {
    const updatedState = {
        healthCheckData: action.healthCheckData
    }
    return updateObject(state, updatedState);
}

const isHealthCheckValid = (state, action) => {
    const updatedState = {
        complete: action.valid
    }
    return updateObject(state, updatedState)
}

const resetHealthCheck = (state) => {
    return updateObject(state, initialState);
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_HEALTH_CHECK:
            return healthCheckComplete(state, action);
        case actionTypes.IS_HEALTH_CHECK_VALID:
            return isHealthCheckValid(state, action);
        case actionTypes.RESET_HEALTH_CHECK:
            return resetHealthCheck(state);
        case actionTypes.HEALTH_CHECK_INIT:
            return updateObject(state, initialState)
        case actionTypes.UPDATE_HEALTH_CHECK_DATA:
            return updateObject(state, { healthCheckData: action.healthCheckData })
        default:
            return state;
    }
}

export default reducer;