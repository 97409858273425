import * as actionTypes from './actionTypes';

export const getManualSubmissionsStart = () => {
    return {
        type: actionTypes.GET_MANUAL_SUBMISSIONS_START
    }
}

export const getManualSubmissions = (UID, location) => {
    return {
        type: actionTypes.GET_MANUAL_SUBMISSIONS,
        UID: UID,
        location: location
    }
}

export const getManualSubmissionsSuccess = (manualSubmissionsData) => {
    return {
        type: actionTypes.GET_MANUAL_SUBMISSIONS_SUCCESS,
        manualSubmissionsData: manualSubmissionsData
    }
}

export const getManualSubmissionsFail = (error) => {
    return {
        type: actionTypes.GET_MANUAL_SUBMISSIONS_FAIL,
        error: error
    }
}

export const completeManualSubmissionStart = () => {
    return {
        type: actionTypes.COMPLETE_MANUAL_SUBMISSION_START
    }
}

export const completeManualSubmission = (UID, manualSubmissionId) => {
    return {
        type: actionTypes.COMPLETE_MANUAL_SUBMISSION,
        UID: UID,
        manualSubmissionId: manualSubmissionId
    }
}

export const completeManualSubmissionSuccess = () => {
    return {
        type: actionTypes.COMPLETE_MANUAL_SUBMISSION_SUCCESS
    }
}

export const completeManualSubmissionFail = (error) => {
    return {
        type: actionTypes.COMPLETE_MANUAL_SUBMISSION_FAIL,
        error: error
    }
}

export const contractUploadEnrolmentStart = () => {
    return {
        type: actionTypes.CONTRACT_UPLOAD_ENROLEMNT_START
    }
}

export const contractUploadEnrolment = (UID, endolmentDataId, sendComms) => {
    return {
        type: actionTypes.CONTRACT_UPLOAD_ENROLEMNT,
        UID: UID,
        enrolmentDataId: endolmentDataId,
        sendComms: sendComms
    }
}

export const contractUploadEnrolmentSuccess = () => {
    return {
        type: actionTypes.CONTRACT_UPLOAD_ENROLEMNT_SUCCESS
    }
}

export const contractUploadEnrolmentFail = (error) => {
    return {
        type: actionTypes.COMPLETE_MANUAL_SUBMISSION_FAIL,
        error: error
    }
}

// onSendEnrolmentComms
// onUploadEnrolmentContract