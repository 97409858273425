import axios from 'axios';
import * as Constants from './shared/constants';

// const instance = axios.create({
//     baseURL: 'https://fp-forms-develop.firebaseio.com/'
// });

const instance = axios.create({
    baseURL: Constants.testInvocation ? Constants.TEST_BASE_URL : Constants.BASE_URL
})

export default instance;