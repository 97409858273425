import * as actionTypes from './actionTypes';

export const legalDetailsComplete = (legalData) => {
    return {
        type: actionTypes.ADD_LEGAL_DETAILS,
        legalData: legalData,
    }
}

export const isLegalDetailsValid = (valid) => {
    return {
        type: actionTypes.IS_LEGAL_DETAILS_VALID,
        valid: valid
    }
}

export const resetLegalDetails = () => {
    return {
        type: actionTypes.RESET_LEGAL_DETAILS
    }
}

export const checkDuplicateKeySuccess = (isDuplicateKey) => {
    return {
        type: actionTypes.CHECK_DUPLICATE_KEY_SUCCESS,
        isDuplicateKey: isDuplicateKey
    }
}

export const checkDuplicatKeyStart = () => {
    return {
        type: actionTypes.CHECK_DUPLICATE_KEY_START
    }
}

export const checkDuplicatKeyFail = (error) => {
    return {
        type: actionTypes.CHECK_DUPLICATE_KEY_FAIL,
        error: error
    }
}

export const initialiseLegalDetails = () => {
    return {
        type: actionTypes.LEGAL_DETAILS_INIT
    }
}

export const updateLegalDetailsData = (legalData) => {
    return {
        type: actionTypes.UPDATE_LEGAL_DETAILS_DATA,
        legalData: legalData
    }
}

