import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import * as actions from './store/actions/index';
import Layout from './hoc/Layout/Layout';
import asyncComponent from './hoc/asyncComponent/asyncComponent';
import Home from './components/Home/Home';
import Logout from './containers/Auth/Logout/Logout';

const asyncFormBuilder = asyncComponent(() => {
  return import('./containers/FormBuilder/FormBuilder')
});

const asyncAuth = asyncComponent(() => {
  return import('./containers/Auth/Auth')
});

const asyncManualSubmissions = asyncComponent(() => {
  return import('./containers/ManualSubmissions/ManualSubmissions')
});

const asyncViewEnrolments = asyncComponent(() => {
  return import('./containers/ViewEnrolments/ViewEnrolments')
});

const asyncUpdateGym = asyncComponent(() => {
  return import('./containers/Settings/UpdateGym/UpdateGym')
})

const asyncUpdateStaff = asyncComponent(() => {
  return import('./containers/Settings/UpdateStaff/UpdateStaff')
})

class App extends Component {

  componentDidMount() {
    this.props.onTryAutoSignUp();
  }

  render() {

    let routes = (
      <Switch>
        <Route path="/auth" component={asyncAuth} />
        <Route path="/" exact component={Home} />
        <Redirect to="/" />
      </Switch>
    );

    if (this.props.isAuthenticated) {
      routes = (
        <Switch>
          <Route path="/settings/updateStaff" component={asyncUpdateStaff} />
          <Route path="/settings/updateGym" component={asyncUpdateGym} />
          <Route path="/viewEnrolments" component={asyncViewEnrolments} />
          <Route path="/manualSubmissions" component={asyncManualSubmissions} />
          <Route path="/enrolment" component={asyncFormBuilder} />
          <Route path="/logout" component={Logout} />
          <Route path="/auth" component={asyncAuth} />
          <Route path="/" exact component={Home} />
          <Redirect to="/" />
        </Switch>
      );
    }

    return (
      <div>
        <Layout>
          <Switch>
            {routes}
          </Switch>
        </Layout>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignUp: () => dispatch(actions.authCheckState())
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
