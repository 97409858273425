import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
import { UNDER18 } from '../../shared/calendar';

const initialState = {
    under18: false,
    memberData: null,
    complete: false
}

const memberDetailsComplete = (state, action) => {
    // console.log('memberDetailsComplete(reducer)'+JSON.stringify(action))
    // console.log('memberDetailsComplete(reducer) under18 '+UNDER18(action.memberData.dob))
    const updatedState = {
        memberData: action.memberData,
        under18: UNDER18(action.memberData.dob)
    }

    return updateObject(state, updatedState);
}

const resetMemberDetails = (state) => {
    return updateObject(state, initialState);
}

const isMemberDetailsValid = (state, action) => {

    // console.log('isMemberDetailsValid(reducer)'+JSON.stringify(action))

    const updatedState = {
        complete: action.valid
    }

    return updateObject(state, updatedState)
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_MEMBER_DETAILS:
            return memberDetailsComplete(state, action);
        case actionTypes.IS_MEMBER_DETAILS_VALID:
            return isMemberDetailsValid(state, action);
        case actionTypes.RESET_MEMBER_DETAILS:
            return resetMemberDetails(state);
        case actionTypes.MEMBER_DETAILS_INIT:
            return updateObject(state, initialState)
        case actionTypes.UPDATE_MEMBER_DETAILS_DATA:
            return updateObject(state, { memberData: action.memberData, under18: UNDER18(action.memberData.dob) })
        default:
            return state;
    }
}

export default reducer;