import { takeEvery, all } from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';
import { logoutSaga, checkAuthTimeoutSaga, authUserSaga, authCheckStateSaga, authRefreshTokenSaga } from './auth';
import { lookUpExistingMemberSaga } from './primaryDetails';
import { getMembershipDetailsSaga, getTrainersSaga, getContractsSaga } from './membershipDetails';
import { purchaseMembershipSaga } from './formBuilder';
import { checkDuplicateKeySaga } from './legalDetails';
import { getManualSubmissionsSaga, completeManualSubmissionsSaga, contractUploadEnrolmentSaga } from './manualSubmissions';
import { getEnrolmentsSaga, searchEnrolmentsSaga } from './viewEnrolments';
import { getUIGymsSaga, getUIStaffSaga, getStaffSaga, updateUIGymSaga, updateStaffSaga } from './uiDetails';


export function* watchAuth() {
    yield all([
        takeEvery(actionTypes.AUTH_INITIATE_LOGOUT, logoutSaga),
        takeEvery(actionTypes.AUTH_CHECK_TIMEOUT, checkAuthTimeoutSaga),
        takeEvery(actionTypes.AUTH_USER, authUserSaga),
        takeEvery(actionTypes.AUTH_CHECK_STATE, authCheckStateSaga),
        takeEvery(actionTypes.AUTH_REFRESH_TOKEN, authRefreshTokenSaga)
    ]);
}

export function* watchClient() {
    yield all([
        takeEvery(actionTypes.LOOK_UP_EXISTING_MEMBER, lookUpExistingMemberSaga)
    ]);
}

export function* watchMemberships() {
    yield all([
        takeEvery(actionTypes.GET_MEMBERSHIP_DETAILS, getMembershipDetailsSaga),
        takeEvery(actionTypes.GET_TRAINERS, getTrainersSaga),
        takeEvery(actionTypes.GET_CONTRACTS, getContractsSaga)
    ]);
}

export function* watchForms() {
    yield all([
        takeEvery(actionTypes.PURCHASE_MEMBERSHIP_INIT, purchaseMembershipSaga)
    ]);
}

export function* watchLegal() {
    yield all([
        takeEvery(actionTypes.CHECK_DUPLICATE_KEY, checkDuplicateKeySaga)
    ]);
}

export function* watchManualSubmissions() {
    yield all([
        takeEvery(actionTypes.GET_MANUAL_SUBMISSIONS, getManualSubmissionsSaga),
        takeEvery(actionTypes.COMPLETE_MANUAL_SUBMISSION, completeManualSubmissionsSaga),
        takeEvery(actionTypes.CONTRACT_UPLOAD_ENROLEMNT, contractUploadEnrolmentSaga)
    ]);
}

export function* watchViewEnrolments() {
    yield all([
        takeEvery(actionTypes.GET_ENROLMENTS , getEnrolmentsSaga),
        takeEvery(actionTypes.SEARCH_ENROLMENTS , searchEnrolmentsSaga)
    ]);
}

export function* watchUIDetails() {
    yield all([
        takeEvery(actionTypes.GET_UI_GYMS, getUIGymsSaga),
        takeEvery(actionTypes.GET_UI_STAFF, getUIStaffSaga),
        takeEvery(actionTypes.GET_STAFF, getStaffSaga),
        takeEvery(actionTypes.UPDATE_UI_GYM, updateUIGymSaga),
        takeEvery(actionTypes.UPDATE_STAFF, updateStaffSaga)
    ]);
}