import * as actionTypes from '../actions/actionTypes';
import { updateObject, isValidUIStaff, isSydneyUIStaff } from '../../shared/utility';
import * as constants from '../../shared/constants';

const initialState = {
    uiGymsData: null,
    uiStaffData: null,
    staffData: null,
    error: null,
    loading: false,
    updating: false
}

const setUIGyms = (state, action) => {
    // console.log('[setUIGyms] ' + JSON.stringify(action.gymsData))
    const updatedState = {
        uiGymsData: action.uiGymsData,
        loading: false,
        submitted: false
    }

    return updateObject(state, updatedState);
}

const setUIStaff = (state, action) => {
    // console.log('[setUIStaff] ' + JSON.stringify(action.uiStaffData))

    let staffList = [];
    if (action.uiStaffData !== null) {
        staffList = action.uiStaffData.filter(s => (
            isValidUIStaff(s.mboId)
        )).map(staff => (
            { value: staff.mboId, displayValue: staff.name }
        ));
    }

    const sorted = staffList.sort(function (a, b) {
        let nameA = a.displayValue.toLowerCase(), nameB = b.displayValue.toLowerCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });

    for (var key in sorted) {
        if (sorted[key].displayValue && !isSydneyUIStaff(sorted[key].value)) {
            sorted[key].displayValue = sorted[key].displayValue + ' (Darwin)'
        }
    }

    const updatedState = {
        uiStaffData: sorted,
        loading: false,
        submitted: false
    }

    return updateObject(state, updatedState);
}

const setStaff = (state, action) => {
    // console.log('[setStaff] ' + JSON.stringify(action.staffData))

    let staffList = [];
    if (action.staffData !== null) {
        staffList = action.staffData.filter(s => (
            parseInt(s.mboId) > 1
        ));
    }
    // console.log('[stafflist] ' + JSON.stringify(staffList))

    const sorted = staffList.sort(function (a, b) {
        let nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });

    for (var key in sorted) {
        if (sorted[key].siteId && !sorted[key].siteId.includes(constants.SYDNEY_SITE_ID)) {
            sorted[key].name = sorted[key].name + ' (Darwin)'
        }
    }

    const updatedState = {
        staffData: sorted,
        loading: false,
        submitted: false
    }
    // console.log('[setStaff] ' + JSON.stringify(updatedState))
    return updateObject(state, updatedState);
}

const updateUIGymSuccess = (state, action) => {
    const updatedState = {
        updating: false
    }
    return updateObject(state, updatedState);
}

const updateStaffSuccess = (state, action) => {
    const updatedState = {
        updating: false
    }
    return updateObject(state, updatedState);
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_UI_GYMS_START:
            return updateObject(state, { error: null, loading: true });
        case actionTypes.GET_UI_GYMS_SUCCESS:
            return setUIGyms(state, action);
        case actionTypes.GET_UI_GYMS_FAIL:
            return updateObject(state, { error: action.error, loading: false });
        // 
        case actionTypes.UPDATE_UI_GYM_START:
            // console.log('actionTypes.UPDATE_UI_GYM_START')
            return updateObject(state, { error: null, updating: true });
        case actionTypes.UPDATE_UI_GYM_SUCCESS:
            return updateUIGymSuccess(state, action);
        case actionTypes.UPDATE_UI_GYM_FAIL:
            // console.log('actionTypes.UPDATE_UI_GYM_FAIL')
            return updateObject(state, { error: action.error, updating: false });
        // 
        case actionTypes.GET_UI_STAFF_START:
            return updateObject(state, { error: null, loading: true });
        case actionTypes.GET_UI_STAFF_SUCCESS:
            return setUIStaff(state, action);
        case actionTypes.GET_UI_STAFF_FAIL:
            return updateObject(state, { error: action.error, loading: false });
        // 
        case actionTypes.GET_STAFF_START:
            return updateObject(state, { error: null, loading: true });
        case actionTypes.GET_STAFF_SUCCESS:
            return setStaff(state, action);
        case actionTypes.GET_STAFF_FAIL:
            return updateObject(state, { error: action.error, loading: false });
        // 
        case actionTypes.UPDATE_STAFF_START:
            return updateObject(state, { error: null, updating: true });
        case actionTypes.UPDATE_STAFF_SUCCESS:
            return updateStaffSuccess(state, action);
        case actionTypes.UPDATE_STAFF_FAIL:
            return updateObject(state, { error: action.error, updating: false });
        default:
            return state;
    }
}

export default reducer;