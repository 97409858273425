import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initalState = {
    responeData: null,
    error: null,
    loading: false,
    isFormValid: false,
    submitted: false,
    submitting: false,
    formData: null,

    showMemberDetails: false,
    showPaymentDetails: false,
    showMembershipDetails: false,
    showHealthCheck: false,
    showLegalDetails: false
}

const purchaseMembershipSuccess = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: false,
        responeData: action.responeData,
        submitted: true
    });
}

const purchaseMembershipFail = (state, action) => {
    // console.log('[purchaseMembershipFail] formData: ' + JSON.stringify(action.formData))
    return updateObject(state, {
        error: action.error,
        loading: false,
        submitting: false,
        formData: action.formData
    });
}

const reducer = (state = initalState, action) => {
    switch (action.type) {
        case actionTypes.PURCHASE_MEMBERSHIP_START:
            return updateObject(state, { error: null, loading: true, submitting: true })
        case actionTypes.PURCHASE_MEMBERSHIP_SUCCESS:
            return purchaseMembershipSuccess(state, action);
        case actionTypes.PURCHASE_MEMBERSHIP_FAIL:
            return purchaseMembershipFail(state, action);
        // return updateObject(state, { error: action.error, loading: false, submitting: false });
        case actionTypes.FORM_BUILDER_INIT:
            return updateObject(state, initalState);
        case actionTypes.SHOW_MEMBER_DETAILS:
            return updateObject(state, { showMemberDetails: true })
        case actionTypes.SHOW_PAYMENT_DETAILS:
            return updateObject(state, { showPaymentDetails: true })
        case actionTypes.SHOW_MEMBERSHIP_DETAILS:
            return updateObject(state, { showMembershipDetails: true })
        case actionTypes.SHOW_HEALTH_CHECK:
            return updateObject(state, { showHealthCheck: true })
        case actionTypes.SHOW_LEGAL_DETAILS:
            return updateObject(state, { showLegalDetails: true })
        default:
            return state;
    }

}

export default reducer;