import axios from '../../axios-instance';
import { put } from 'redux-saga/effects';

import * as actions from '../actions/index';
import { VERBOSE } from '../../shared/constants';

export function* purchaseMembershipSaga(action) {

    yield put(actions.purchaseMembershipStart());

    try {

        if (VERBOSE) console.log('[purchaseMembershipSaga] action.formData ' + JSON.stringify(action.formData))

        const response = yield axios.post('purchaseMembership', action.formData)
        // const response = yield axios.post('TestSubmission', action.formData)
        yield put(actions.purchaseMembershipSuccess(response.data))
    } catch (error) {
        yield put(actions.purchaseMembershipFail(error, action.formData));
    }

}