import * as actionTypes from './actionTypes';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (token, userId, refreshToken) => {

    return {
        type: actionTypes.AUTH_SUCCESS,
        idToken: token,
        userId: userId,
        refreshToken: refreshToken
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const logout = () => {
    return {
        type: actionTypes.AUTH_INITIATE_LOGOUT
    };
};

export const logoutSucceed = () => {
    return {
        type: actionTypes.AUTH_LOGOUT,
    };
};

export const checkAuthTimeout = (experationTime) => {
    return {
        type: actionTypes.AUTH_CHECK_TIMEOUT,
        experationTime: experationTime
    };
};

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    }
}

export const auth = (email, password, isSignUp) => {
    return {
        type: actionTypes.AUTH_USER,
        email: email,
        password: password,
        isSignUp: isSignUp
    }
    
}

export const authCheckState = () => {
    return {
        type: actionTypes.AUTH_CHECK_STATE
    }; 
}

export const setStaffMember = (staffMember) => {

    // console.log("[authSuccess] staffMember: "+ JSON.stringify(staffMember))

    return {
        type: actionTypes.SET_STAFF_MEMBER,
        staffMember: staffMember
    }
}

export const refreshToken = (refreshToken, refreshCount) => {
    return {
        type: actionTypes.AUTH_REFRESH_TOKEN,
        refreshToken: refreshToken,
        refreshCount: refreshCount
    }
}