import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    primaryDetailsData: null,
    complete: false,
    existingClient: false,
    // added: false
}

// refactor to look up existing member
const lookUpExisitingClientsSuccess = (state, action) => {
    const newPrimaryDetails = updateObject(action.primaryDetailsData);

    // console.log("lookUpExisitingClientsSuccess: "+JSON.stringify(action.primaryDetailsData) + " exists " + action.existingClient)
    return updateObject(state, {
        loading: false,
        // added: true,
        // primaryDetailsData: state.primaryDetailsData.concat(newPrimaryDetails)
        existingClient: action.existingClient,
        primaryDetailsData: newPrimaryDetails
    });
};

const primaryDetailsComplete = (state, action) => {

    const updatedState = {
        primaryDetailsData: action.primaryDetailsData
    }
    return updateObject(state, updatedState);
}

const resetPrimaryDetails = (state) => {
    return updateObject(state, initialState);
}

const isPrimaryDetailsValid = (state, action) => {
    const updatedState = {
        complete: action.valid
    }
    return updateObject(state, updatedState)
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_PRIMARY_DETAILS:
            return primaryDetailsComplete(state, action);
        case actionTypes.IS_PRIMARY_DETAILS_VALID:
            return isPrimaryDetailsValid(state, action);
        case actionTypes.RESET_PRIMARY_DETAILS:
            return resetPrimaryDetails(state);
        // case actionTypes.LOOK_UP_EXISTING_MEMBER_INIT:
        //     // return updateObject(state, { found: false });
        case actionTypes.LOOK_UP_EXISTING_MEMBER_START:
            return updateObject(state, { loading: false });
        case actionTypes.LOOK_UP_EXISTING_MEMBER_SUCCESS:
            return lookUpExisitingClientsSuccess(state, action);
        case actionTypes.LOOK_UP_EXISTING_MEMBER_FAIL:
            return updateObject(state, { loading: false });
        case actionTypes.PRIMARY_DETAILS_INIT:
            return updateObject(state, initialState);
        case actionTypes.UPDATE_PRIMARY_DETAILS_DATA:
            return updateObject(state, { primaryDetailsData: action.primaryDetailsData })
        default:
            return state;
    }
}

export default reducer;