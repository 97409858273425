import * as actionTypes from './actionTypes';

export const getUIGymsStart = () => {
    return {
        type: actionTypes.GET_UI_GYMS_START
    }
}

export const getUIGymsSuccess = (uiGymsData) => {
    return {
        type: actionTypes.GET_UI_GYMS_SUCCESS,
        uiGymsData: uiGymsData
    }
}

export const getUIGymsFail = (error) => {
    return {
        type: actionTypes.GET_UI_GYMS_FAIL,
        error: error
    }
}

export const getUIGyms = (UID) => {
    return {
        type: actionTypes.GET_UI_GYMS,
        UID: UID
    }
}

// 
export const updateUIGymStart = () => {
    return {
        type: actionTypes.UPDATE_UI_GYM_START
    }
}

export const updateUIGymSuccess = () => {
    return {
        type: actionTypes.UPDATE_UI_GYM_SUCCESS
    }
}

export const updateUIGymFail = (error) => {
    return {
        type: actionTypes.UPDATE_UI_GYM_FAIL,
        error: error
    }
}

export const updateUIGym = (UID, updateUIGymData) => {
    return {
        type: actionTypes.UPDATE_UI_GYM,
        UID: UID,
        updateUIGymData: updateUIGymData
    }
}

// 

export const getUIStaffStart = () => {
    return {
        type: actionTypes.GET_UI_STAFF_START
    }
}

export const getUIStaffSuccess = (uiStaffData) => {
    return {
        type: actionTypes.GET_UI_STAFF_SUCCESS,
        uiStaffData: uiStaffData
    }
}

export const getUIStaffFail = (error) => {
    return {
        type: actionTypes.GET_UI_STAFF_FAIL,
        error: error
    }
}

export const getUIStaff = (UID) => {
    return {
        type: actionTypes.GET_UI_STAFF,
        UID: UID
    }
}

// 

export const getStaffStart = () => {
    return {
        type: actionTypes.GET_STAFF_START
    }
}

export const getStaffSuccess = (staffData) => {
    return {
        type: actionTypes.GET_STAFF_SUCCESS,
        staffData: staffData
    }
}

export const getStaffFail = (error) => {
    return {
        type: actionTypes.GET_STAFF_FAIL,
        error: error
    }
}

export const getStaff = (UID) => {
    return {
        type: actionTypes.GET_STAFF,
        UID: UID
    }
}

// 

export const updateStaffStart = () => {
    return {
        type: actionTypes.UPDATE_STAFF_START
    }
}

export const updateStaffSuccess = (staffData) => {
    return {
        type: actionTypes.UPDATE_STAFF_SUCCESS,
        staffData: staffData
    }
}

export const updateStaffFail = (error) => {
    return {
        type: actionTypes.UPDATE_STAFF_FAIL,
        error: error
    }
}

export const updateStaff = (UID, updateStaffData) => {
    return {
        type: actionTypes.UPDATE_STAFF,
        UID: UID,
        updateStaffData: updateStaffData
    }
}