// To run
// docker-compose up
// docker-compose down

// To Build - with Docker
// docker build --build-arg BUILD_APP=true -t fp-forms .
// docker run --name fp-forms --rm  -p 3000:3000 fp-forms
// docker cp fp-forms:/app/build .
// docker stop fp-forms

// To Build - without Docker
// npm run build

// https://firebase.google.com/docs/cli#install-cli-mac-linux
// firebase login:ci
// firebase ... --token "..."
// firebase deploy

export const testInvocation = false;

export const BASE_URL = 'https://source.fitnessplayground.com.au/v1/source/';
export const TEST_BASE_URL = 'https://test.fitnessplayground.com.au/v1/source/';

export const VERBOSE = false;
export const TESTING = false;

export const ACCESS_KEY_FREE = 'KeyFree';
export const ACCESS_KEY_21 = 'Key21';
export const ACCESS_KEY_30 = 'Key30';
export const ACCESS_KEY_49 = 'Key49';
export const ACCESS_KEY_79 = 'Key79';
export const ACCESS_KEY_89 = 'Key89';

export const DAYS_FREE_7 = '7DaysFree';
export const DAYS_FREE_14 = '14DaysFree';
export const DAYS_FREE_21 = '21DaysFree';
export const DAYS_FREE_30 = '30DaysFree';
export const X_DAY_FREE_SPECIAL = 'XDaysFree';
export const X_DAY_FREE_SPECIAL_START_DATE = '2023-01-03';

export const PT_PACK_FREE = 'FreePTPack';
export const TWO_FREE_SESSIONS = 'TwoFreePT';

export const CORP = 'CORP';

export const PENDING = 'PENDING';
export const SAVED = "SAVED";
export const ADDING = 'ADDING';
export const INACTIVE = 'INACTIVE';
export const ADDED = 'ADDED';
export const TEMP_MEMBERSHIP = 'TEMP_MEMBERSHIP';
export const MANUAL = 'MANUAL';
export const ACTIVE = 'ACTIVE';
export const DUPLICATE_KEY_DIFF_MEMEBER = 'DUPLICATE_KEY_DIFF_MEMBER';
export const DUPLICATE_KEY_SAME_MEMBER = 'DUPLICATE_KEY_SAME_MEMBER';
export const DUPLICATE_KEY = "DUPLICATE_KEY";
export const DUPLICATE_USERNAME = 'DUPLICATE_USERNAME';
export const ERROR = 'ERROR';
export const PURCHASE_CONTRACT_ERROR = 'PURCHASE_CONTRACT_ERROR';
export const EXTERNAL_PT = 'EXTERNAL_PT';
export const PT_PIF = 'PT_PIF';
export const SUCCESS = 'SUCCESS';
export const ADD_DIRECT_DEBIT_INFO_ERROR = 'ADD_DIRECT_DEBIT_INFO_ERROR';
export const MEMBERSHIP_TRANSFER = 'MEMBERSHIP_TRANSFER';
export const CLIENT_NOT_FOUND = 'CLIENT_NOT_FOUND';
export const SUSPENSION_PENDING = "SUSPENSION_PENDING";
export const SUSPENSION_ERROR = "SUSPENSION_ERROR";
export const CANCELLATION_SAVED = "CANCELLATION_SAVED";
export const CANCELLATION_ERROR = "CANCELLATION_ERROR";

export const FIREBASE_AUTH_VERIFY_PASSWORD_URL = 'https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPassword?key=AIzaSyCqVxKsEKHEkXyTDlit7ICJCl9BsUKzjA0';
export const FIREBASE_AUTH_SIGN_UP_NEW_USER_URL = 'https://www.googleapis.com/identitytoolkit/v3/relyingparty/signupNewUser?key=AIzaSyCqVxKsEKHEkXyTDlit7ICJCl9BsUKzjA0';

export const ACCESS_KEY_PRICE=99;

export const PERMISSION_LEVEL_GOD = 'God';
export const PERMISSION_LEVEL_MANAGER = 'Manager';
export const PERMISSION_LEVEL_ADMINISTRATOR = 'Administrator';
export const PERMISSION_LEVEL_MEMBERSHIP_CONSULTANT = 'Membership Consultant';
export const PERMISSION_LEVEL_PERSONAL_TRAINER = 'Personal Trainer';
export const PERMISSION_LEVEL_EXERCISE_PHYSIOLOGIST = 'Exercise Physiologist';
export const PERMISSION_LEVEL_HEAD_COACH = 'Head Coach';
export const PERMISSION_LEVEL_DEPARTMENT_HEAD_COACH = 'Coaching Department Head';
export const PERMISSION_LEVEL_LOCATION = 'Location'
export const PERMISSION_LEVEL_TESTER = 'Tester';
export const PERMISSION_LEVEL_PEASANT = 'Peasant';

export const COACH_LEVEL_EC = "Elevated Coach"
export const COACH_LEVEL_1 = "Level 1"
export const COACH_LEVEL_2 = "Level 2"
export const COACH_LEVEL_3 = "Level 3"
export const COACH_LEVEL_4 = "Level 4"

export const MBO_ID_AND_SITE_ID_DELINEATOR = '::';
export const SYDNEY_SITE_ID = '152065';

export const ASSIGN_TO_PTM_ID = '-10';
export const NO_COMP_SESSION_ID = '-1000';