import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    paymentData: null,
    complete: false
}

const paymentDetailsComplete = (state, action) => {
    // console.log('paymentDetailsComplete(reducer)'+JSON.stringify(action))
    const updatedState = {
        paymentData: action.paymentData
    }

    return updateObject(state, updatedState);
}

const resetPaymentDetails = (state) => {
    return updateObject(state, initialState);
}

const isPaymentDetailsValid = (state, action) => {

    // console.log('isPaymentDetailsValid(reducer)'+JSON.stringify(action))

    const updatedState = {
        complete: action.valid
    }

    return updateObject(state, updatedState)
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_PAYMENT_DETAILS:
            return paymentDetailsComplete(state, action);
        case actionTypes.IS_PAYMENT_DETAILS_VALID:
            return isPaymentDetailsValid(state, action);
        case actionTypes.RESET_PAYMENT_DETAILS:
            return resetPaymentDetails(state);
        case actionTypes.PAYMENT_DETAILS_INIT:
            return updateObject(state, initialState);
        case actionTypes.UPDATE_PAYMENT_DETAILS_DATA:
            return updateObject(state, { paymentData: action.paymentData })
        default:
            return state;
    }
}

export default reducer;