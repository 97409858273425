import * as actionTypes from './actionTypes';

export const purchaseMembershipStart = () => {
    return {
        type: actionTypes.PURCHASE_MEMBERSHIP_START
    }
}

// This could be the memory leak because I'm trying to set state on the container after I unmount
export const purchaseMembershipSuccess = (responseData) => {

    return {
        type: actionTypes.PURCHASE_MEMBERSHIP_SUCCESS,
        responseData: responseData,
    };
};

export const purchaseMembershipFail = (error, formData) => {
    return {
        type: actionTypes.PURCHASE_MEMBERSHIP_FAIL,
        error: error,
        formData: formData
    };
};

export const purchaseMembership = (formData) => {
    return {
        type: actionTypes.PURCHASE_MEMBERSHIP_INIT,
        formData: formData
    }
}

export const initialiseFormBuilder = () => {
    return {
        type: actionTypes.FORM_BUILDER_INIT
    }
}

export const showMemberDetails = () => {
    return {
        type: actionTypes.SHOW_MEMBER_DETAILS
    }
}

export const showPaymentDetails = () => {
    return {
        type: actionTypes.SHOW_PAYMENT_DETAILS
    }
}

export const showMembershipDetails = () => {
    return {
        type: actionTypes.SHOW_MEMBERSHIP_DETAILS
    }
}

export const showHealthCheck = () => {
    return {
        type: actionTypes.SHOW_HEALTH_CHECK
    }
}

export const showLegalDetails = () => {
    return {
        type: actionTypes.SHOW_LEGAL_DETAILS
    }
}