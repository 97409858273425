import * as actionTypes from './actionTypes';

export const paymentDetailsComplete = (paymentData) => {
    return {
        type: actionTypes.ADD_PAYMENT_DETAILS,
        paymentData: paymentData,
    }
}

export const isPaymentDetailsValid = (valid) => {
    return {
        type: actionTypes.IS_PAYMENT_DETAILS_VALID,
        valid: valid
    }
}

export const resetPaymentDetails = () => {
    return {
        type: actionTypes.RESET_PAYMENT_DETAILS
    }
}

export const initialisePaymentDetails = () => {
    return {
        type: actionTypes.PAYMENT_DETAILS_INIT
    }
}

export const updatePaymentDetailsData = (paymentData) => {
    return {
        type: actionTypes.UPDATE_PAYMENT_DETAILS_DATA,
        paymentData: paymentData
    }
}