import * as actionTypes from './actionTypes';

// export const SET_SIGNATURE = 'SET_SIGNATURE';
// export const GET_SIGNATURE = 'GET_SIGNATURE';
// export const VALIDATE_SIGNATURE = 'VALIDATE_SIGNATURE';

export const clearSignature = (identifier) => {
    return {
        type: actionTypes.CLEAR_SIGNATURE,
        identifier: identifier
    }
}

export const setSignature = (signatureURL, valid, identifier) => {
    // console.log('[setSignature] '+signatureURL+' | '+valid+' | '+identifier)
    return {
        type: actionTypes.SET_SIGNATURE,
        signatureURL: signatureURL,
        valid: valid,
        identifier: identifier
    }
}

export const initialiseSignature = () => {
    return {
        type: actionTypes.SIGNATURE_INIT
    }
}