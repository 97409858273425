import axios from '../../axios-instance';
import { put } from 'redux-saga/effects';
import * as actions from '../actions/index';

export function* getManualSubmissionsSaga(action) {

    yield put(actions.getManualSubmissionsStart());

    try {
        const response = yield axios.get('getManualSubmissions?UID=' + action.UID + '&location=' + action.location)        
        // console.log("[getManualSubmissionsSaga] "+JSON.stringify(response.data))
        yield put(actions.getManualSubmissionsSuccess(response.data))
    } catch (error) {
        yield put(actions.getManualSubmissionsFail(error, action.formData));
    }
}

export function* completeManualSubmissionsSaga(action) {

    yield put(actions.completeManualSubmissionStart());

    // console.log("[completeManualSubmissionsSaga] "+JSON.stringify(action)+' completeManualSubmissions?UID='+action.UID+'&enrolmentSubmissionErrorId='+action.manualSubmissionId)

    try {
        const response = yield axios.get('completeManualSubmissions?UID=' + action.UID + '&enrolmentSubmissionErrorId=' + action.manualSubmissionId)
        // console.log("[completeManualSubmissionsSaga] "+JSON.stringify(response.data))
        yield put(actions.completeManualSubmissionSuccess(response.data))
    } catch (error) {
        yield put(actions.completeManualSubmissionFail(error, action.formData));
    }
}

export function* contractUploadEnrolmentSaga(action) {

    yield put(actions.contractUploadEnrolmentStart());

    try {
        const reqBody = {
            enrolmentDataId: action.enrolmentDataId,
            sendComms: action.sendComms
        }                
        yield axios.put('contractUploadEnrolment?UID=' + action.UID, reqBody)                
        yield put(actions.contractUploadEnrolmentSuccess())

    } catch (error) {
        yield put(actions.contractUploadEnrolmentFail);
    }
} 